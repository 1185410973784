import * as schema from './schema';
import * as core from '../model/core';

export * from './schema';

export type ResultObject<T> = { [key: string]: T };

export function queryStringToObject<T = ResultObject<string>>(
  queryString: string,
  schemaObject?: schema.QueryStringSchema<T>): Partial<T> {

  const qs = queryString?.replace(/^\?+/g, '') ?? '';
  const res: ResultObject<{}> = {};

  for (let kv of qs.split('&')) {
    let [key, value] = kv.split('=');

    key = key ? decodeURIComponent(key.trim()) : '';

    if (!key) {
      continue;
    }

    if (key === 'type') {
      value = (core.VehicleType as any)[value];
    }

    value = value ? decodeURIComponent(value) : '';

    const schemaObj = schemaObject
      ? (schemaObject as any)[key] as schema.SchemaType | undefined
      : schema.string();

    if (!schemaObj) {
      continue;
    }

    const handledValue = schemaObj.handle(key, value);

    if (handledValue != null) {
      res[key] = handledValue;
    }
  }

  return res as Partial<T>;
}

interface ToQueryStringMapper {
  map: (key: string, value: any) => string | null;
}

export function objectToQueryString(obj: any, mapper?: ToQueryStringMapper): string {
  if (!obj) {
    return '';
  }

  return '?' + Object.keys(obj)
    .filter(key => !!obj[key])
    .map(key => {
      if (mapper) {
        const value = mapper.map(key, obj[key]);

        if (value != null) {
          return value;
        }
      }

      let param = obj[key];

      if(key === 'type')
      {
        param = core.VehicleType[param];
      }

      // return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
   
      return `${encodeURIComponent(key)}=${encodeURIComponent(param)}`;
    })
    .join('&');
}

/**
 * A query mapper compatible with default enumerable type parameters in .net webapi.
 * The same key will be used for each value.
 */
export const arrayQueryStringMapper: ToQueryStringMapper = {
  map(key, value) {
    if (Array.isArray(value)) {
      return value.map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
    }

    return null;
  }
};