import * as query from '../../query';
import { ViewHandler, ViewChangeCallback, RemoveChangeEvent } from './viewHandler';
import { View, views, ViewType, ViewHandlerType } from '.';

/**
 * Parse and handle search query string.
 */
export class SearchViewHandler extends ViewHandler {

  viewType() {
    return ViewHandlerType.Search;
  }

  getLinkFromView(view: View, queryString?: string) {
    return window.location.pathname + (queryString ?? '');
  }

  onViewChange(callback: ViewChangeCallback): RemoveChangeEvent {
    const handleChange = (e: PopStateEvent) => {
      const queryString = location.search;
      const view = this.parseView(queryString, true);

      if (view) {
        callback(view, new URL(location.toString()));
      }
    };

    window.addEventListener('popstate', handleChange);

    return () => {
      window.removeEventListener('popstate', handleChange);
    };
  }

  parseView(path?: string, assign = false) {
    const queryString = path ?? location.search;
    const queryObject = query.queryStringToObject(queryString);

    const view = 'item' in queryObject
      ? views[ViewType.Object]
      : views[ViewType.ObjectFilter];

    view.query = queryString;

    if (assign) {
      this.setViewType(view.view);
    }

    return view;
  }
}