import { GeneralConfig } from '../model/core';

export function setCss(config: GeneralConfig) {
  const head = document.getElementsByTagName('head')[0];
  const styleTagId = 'osCssVariables';
  const styleSheetId = 'osCss';

  if (!head) {
    return;
  }

  if (!document.getElementById(styleTagId)) {
    const styleTag = document.createElement('style');
    const entries = Object.entries(config.branding);
    let variables = '';

    for (const entry of entries) {
      const [key, value] = entry;
      variables += `--os-${key}: ${value};`;
    }

    styleTag.id = 'osCssVariables';
    styleTag.innerText = `:root, :host {${variables}}`;

    head.appendChild(styleTag);
  }

  if (!document.getElementById(styleSheetId)) {
    const link = document.createElement('link');

    link.id = styleSheetId;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = config.cssLink;
    link.media = 'all';

    head.appendChild(link);
  }
}