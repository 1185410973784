import { Result, error, ok } from '@empori/util';

function transformJson(this: any, key: string, value: any) {
  if (key && key.length >= 2 && key.indexOf('@') === 0 && value !== null) {
    this[key.substring(1)] = new Date(value);
    delete this[key];
    return;
  }

  return value;
}

function replacerJson(this: any, key: string, value: any) {
  if (value && typeof value === 'object' && !(value instanceof Date)) {
    for (const objKey of Object.keys(value)) {
      const objVal = value[objKey];

      if (objVal instanceof Date) {
        value['@' + objKey] = objVal.toJSON();
      }
    }
  }

  return value;
}

export async function requestJson<T>(input: RequestInfo | URL, init?: RequestInit): Promise<Result<T>> {
  try {
    const response = await fetch(input, init);
    const jsonText = await response.text();
    const json = JSON.parse(jsonText, transformJson) as T;
    return ok(json);
  } catch (ex) {
    return error(`Could not request or parse response from ${input}: ${ex}`);
  }
}

export async function getJson<T>(url: string) {
  return await requestJson<T>(url);
}

export async function postJson<TRes, TBody>(url: string, body: TBody) {
  return await requestJson<TRes>(url, {
    method: 'POST',
    body: JSON.stringify(body, replacerJson),
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
