import { ViewHandler, ViewChangeCallback, RemoveChangeEvent } from './viewHandler';
import { View, views, ViewHandlerType } from '.';

/**
 * Parse and handle hash string.
 */
export class HashViewHandler extends ViewHandler {

  viewType() {
    return ViewHandlerType.Hash;
  }

  getLinkFromView(view: View, queryString?: string ) {
    return `#${view.key}${queryString}`;
  }

  onViewChange(callback: ViewChangeCallback): RemoveChangeEvent {
    const handleChange = (e: HashChangeEvent) => {
      const url = new URL(e.newURL); // TODO: Polyfill för URL?
      const view = this.parseView(url.hash, true);

      if (view) {
        callback(view, url);
      }
    };

    window.addEventListener('hashchange', handleChange);

    return () => {
      window.removeEventListener('hashchange', handleChange);
    };
  }

  parseView(path?: string, assign = false) {
    const [viewKey, queryString] = (path ?? window.location.hash)?.replace(/^\#+/g, '').split('?');

    const view = Object.keys(views)
      .map(key => (views as any)[key] as View)
      .find(v => v.key === viewKey);

    if (view) {
      view.query = queryString;

      if (assign) {
        this.setViewType(view.view);
      }

      return view;
    }

    return null;
  }
}