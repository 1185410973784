import { View, ViewType, views, ViewHandlerType } from '.';

export type RemoveChangeEvent = { (): void };
export type ViewChangeCallback = (view: View, url: URL) => void;

export interface HistoryState {
  currentView: ViewType;
  prevView: ViewType | null;
  link: string;
}

export abstract class ViewHandler {
  protected prevView: ViewType | null = null;
  protected currentView: ViewType | null = null;

  setViewType(viewType: ViewType) {
    this.prevView = this.currentView;
    this.currentView = viewType;
  }

  getView() {
    if (this.currentView == null) {
      const view = this.parseView();

      if (view) {
        this.setViewType(view.view);
        return view;
      }

      return null;
    }

    return views[this.currentView];
  }

  setView(view: ViewType, queryString?: string, push = false) {
    const obj = views[view];
    obj.query = queryString;

    const link = this.getLinkFromView(obj, queryString);
    const state: HistoryState = { link, currentView: view, prevView: this.currentView };

    if (push || this.currentView !== view) {
      window.history.pushState(state, '', link);
    } else {
      window.history.replaceState(state, '', link);
    }

    this.setViewType(view);
  }

  abstract getLinkFromView(view: View, queryString?: string): string;
  abstract parseView(path?: string, assign?: boolean): View | null;
  abstract onViewChange(callback: ViewChangeCallback): RemoveChangeEvent;
  abstract viewType(): ViewHandlerType;
}
