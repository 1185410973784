import * as core from '../model/core';
import { objectToQueryString, arrayQueryStringMapper } from '../query';
import { getJson, postJson } from './api';

let apiBaseUrl = '/';
const apiPathPrefix = 'osApi/v1';

export function setApiBaseUrl(url: string) {
  apiBaseUrl = url;
}

function vehicleApi<TQuery>(path: string, queryObject?: TQuery) {
  return apiBaseUrl + apiPathPrefix + path + objectToQueryString(queryObject, arrayQueryStringMapper);
}

export async function getFilterData(query: Partial<core.FilterQueryParameters>) {
  return await getJson<core.FilterData | null>(vehicleApi('/filterData', query));
}

export async function getObjects(query: Partial<core.ObjectQueryParameters>) {
  return await getJson<core.ObjectQueryResult>(vehicleApi('/objects', query));
}

export async function getObject(uniqueId: string) {
  return await getJson<core.ItemResult | null>(vehicleApi(`/object/${uniqueId}`));
}

export async function getGeneralConfig() {
  return await getJson<core.GeneralConfig>(vehicleApi('/generalConfig'));
}

export async function postInterest(form: Partial<core.InterestForm>) {
  return await postJson<boolean, core.InterestForm>(vehicleApi('/interest'), {
    ...form,
    referer: window.location.href
  } as core.InterestForm);
}

export async function mailFavourites(favourites: core.FavoritesRequest) {
  return await postJson<boolean, core.FavoritesRequest>(vehicleApi('/favourites'), favourites);
}

export async function getEffectiveRate(query: core.EffectiveRateQueryParameters) {
  return await getJson<number>(vehicleApi('/effectiveRate', query));
}

export async function getInterestFormSettings() {
  return await getJson<core.InterestFormSettings>(vehicleApi('/interestformsettings'));
}