import * as React from 'react';

export function ObjectViewSkeleton() {
  return (
    <div className='dso-skeleton dso-object-view-skeleton'>
      <div className='dso-skeleton-main-section'>
        <div className='dso-skeleton-item'></div>
      </div>

      <div className='dso-skeleton-sidebar-section'>
        <div className='dso-skeleton-item'></div>
        <div className='dso-skeleton-item'></div>
      </div>
    </div>
  );
}

export function SearchResultSkeleton() {
  return (
    <div className='dso-skeleton dso-filter-skeleton'>
      <div className='dso-skeleton-object'>
        <div className='dso-skeleton-item'></div>
      </div>

      <div className='dso-skeleton-object'>
        <div className='dso-skeleton-item'></div>
      </div>

      <div className='dso-skeleton-object'>
        <div className='dso-skeleton-item'></div>
      </div>
    </div>
  );
}

export function DsoSuspense<T = {}>(props: React.PropsWithChildren<T>) {
  return(
    <React.Suspense fallback={<ObjectViewSkeleton />}>
      {props.children}
    </React.Suspense>
  );
}