import * as React from 'react';
import * as core from './model/core';
import { autobind } from './decorator';
import { handleObjectDirectLink } from './app';
import { ObjectActionCallback } from './view/filter/objectFilterView';
import { EssentialSettings } from './main';
import { QueryObjects } from './view/query/queryObjects';

export interface DsoQuerySettings extends EssentialSettings {
  objectLink: ObjectActionCallback;
  query: Partial<core.ObjectQueryParameters>;
}

interface Props {
  settings: DsoQuerySettings;
}

export class QueryObjectsApp extends React.Component<Props> {

  @autobind
  handleSelectObject(object: core.Vehicle | null) {
    const { objectLink } = this.props.settings;

    if (!handleObjectDirectLink(object, true, objectLink)) {
      console.warn('Chosen objects can only be used with a direct external link');
    }
  }

  render() {
    const { objectLink, query } = this.props.settings;

    return (
      <div className='dso-app-container'>
        <QueryObjects
          query={query}
          selectObject={this.handleSelectObject}
          objectLink={objectLink} />
      </div>
    );
  }
}