/*
 * Generated from OnlineShowroom.Frontend.TypeScriptTypingGenerator
 * 
 * ** Do not edit manually! **
 */

export interface EffectiveRateQueryParameters {
    nrOfMonthlyPayments: number;
    monthlyPayment: number;
    invoiceFee: number;
    totalCost: number;
    downPayment: number;
    setupFee: number;
    stateFee: number;
}

export interface FavoritesRequest {
    email: string;
    vehicleIds: string[];
}

export interface GeneralConfig {
    mainPhoneNumber: string;
    mainEmailAddress: string;
    mainPostalAddress: string;
    mainDealershipName: string;
    suggestedMonthCost: boolean;
    defaultGridView: boolean;
    showArticleNr: boolean;
    share: VehicleShare;
    printActive: boolean;
    showPriceForSoldObjects: boolean;
    showDateInTraffic: boolean;
    hideModelYearForNewObjects: boolean;
    googleTagManagerId: string;
    financingTextNewObjects: string;
    financingTextUsedObjects: string;
    objectPageTitle: string
    youTubeButtonText: string;
    baseUrl: string;
    language: string;
    itemsPerPage: number;
    version: string;
    cssLink: string;
    branding: Branding;
    campaignBoxes: CampaignBox[];
}

export interface VehicleShare {
    facebook: boolean;
    messenger: boolean;
    mail: boolean;
    link: boolean;
}

export interface Branding {
    logoPath: string;
    primaryColor: string;
    primaryAltColor: string;
    secondaryColor: string;
    fontColor: string;
    fontColorDarkMode: string;
}

export interface CampaignBox {
    yearModel: number;
    vehicleType: VehicleType;
    boxColor: string;
    textColor: string;
    textProductListing: string;
    textProductDetail: string;
    headlineProductDetail: string;
    isActive: boolean;
}

export enum VehicleType {
    unknown = -1,
    anything = 0,
    caravan = 1,
    mobileHome = 2,
    car = 3,
    mcAtv = 4,
    snowmobile = 5,
    boat = 6,
    trailer = 7,
    transport = 8,
    jetski = 9,
    boatEngine = 10
}

export interface InterestForm {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    message: string;
    uniqueId: string;
    referer: string;
    turnstileToken: string;
    empcaptchaValue: string;
}

export interface InterestFormSettings {
    spamProtectionConfig: SpamProtectionConfig;
}

export interface SpamProtectionConfig {
    isEmporiCaptchaEnabled: boolean;
    isTurnstileEnabled: boolean;
    fetchUrlEmporiCaptcha: string;
    captchaImgId: string;
    turnstileSiteKey: string;
    turnstileClientTokenAPIUrl: string;
}

/**
 * Single item result.
 */
export interface ItemResult {
    item: VehicleExtended;
}

export interface VehicleExtended extends Vehicle {
    description: string;
    adInfo: string;
    equipment: VehicleEquipment[];
    descriptionEquipments: DescriptionEquipment[];
    sellers: Employee[];
    relatedObjects: Vehicle[];
    youTube: string;
    dateInTraffic?: Date;
    images360: FileViewResourceDto;
    buyOnline: BuyOnlineType;
    financeType: CheckoutFinanceType;
    showExchange: boolean;
    heating: string[];

    /**
     * Source origin name for the vehicle.
     */
    source: string;

    /**
     * External file resources associated with this vehicle.
     */
    files: FileResource[];
    impelWidgetUrl: string;
}

export interface VehicleEquipment {
    articleNr: string;
    name: string;
    price: number;
}

export interface DescriptionEquipment {
    name: string;
}

export interface Employee {
    name: string;
    title: string;
    description: string;
    phoneNumber: string;
    email: string;
    image: string;
    filials?: any;
}

export interface Vehicle {

    /**
     * Gets or sets unique object id.
     */
    uniqueId: string;
    created: Date;
    updatedAt: Date;
    soldDate: Date;
    brand: string;
    category: string;
    model: string;
    year: number;
    mileage: number;
    gearbox?: VehicleGearbox;
    fuel: VehicleFuel;
    engine: string;
    type: VehicleType;
    operatingHours: number;
    status: VehicleStatus;
    vehicleWheelDrive?: VehicleWheelDrive;
    effect: number;
    totalWeight: number;
    curbWeight: number;
    cargoCapacity: number;
    regNr: string;
    regMark: string;
    title: string;
    condition?: Condition;
    images: FileViewResourceDto;
    price: number;
    salePrice?: number;
    pricePerMonth: number;
    priceExTax: boolean;
    essentialSpecs: VehicleSpec[];
    specifications: VehicleSpec[];
    companyPhoneNumber: string;
    companyEmailAddress: string;
    pageId?: number;
    pageLink: string;

    /**
     * Gets or sets object friendly id. This is usually the unique part/identifier of a page URL slug.
     */
    friendlyId: string;

    /**
     * Gets or sets object article number. This can be used as a reference to an external system.
     */
    articleNr: string;
    filial: string;
    filialBranch: string;
    content: ContentStatus;
    beds: number;
    layout?: VehicleFloorPlan;
    length: number;
    width: number;
    height: number;
    passengers: number;
    costCalc: ICostCalculation;
}

export enum VehicleGearbox {
    manual = 0,
    automatic = 1,
    sequential = 2
}

export enum VehicleFuel {
    unknown = 0,
    gasoline = 1,
    diesel = 2,
    hybridGasoline = 3,
    hybridDiesel = 4,
    naturalGas = 5,
    electricity = 6,
    gasolineEthanol = 7,
    gasolineGas = 8
}

export enum VehicleStatus {
    forSale = 0,
    reserved = 1,
    sold = 2
}

export enum VehicleWheelDrive {
    unknown = 0,
    frontWheel = 1,
    rearWheel = 2,
    fourWheel = 3
}

export enum Condition {
    all = 0,
    new = 1,
    used = 2
}

export interface FileViewResourceDto {
    resourceBaseBath: string;
    fileIds: string[];
}

export interface VehicleSpec {
    title: string;
    value: string;
    icon: string;
    category: string;
}

export enum ContentStatus {
    none = 0,
    video = 1,
    images360 = 2
}

export enum VehicleFloorPlan {
    alcoveBed = 0,
    cornerBed = 1,
    doubleBed = 2,
    longBeds = 3,
    unknown = 4,
    nursery = 5,
    other = 6,
    queenBed = 7,
    roofBed = 8
}

export interface ICostCalculation {
    downPaymentMaximum: number;
    downPaymentMinimum: number;
    initialDownPayment: number;
    initialLoanPaymentYears: number;
    vehicleMaxAge: number;
    loanInterest: number;
    loanPaymentYearsMaximum: number;
    monthlyPaymentMinimum: number;
    monthSteps: number;
    stateFee: number;
    setupFee: number;
    invoiceFee: number;
}

export enum BuyOnlineType {
    none = 0,
    cash = 1,
    finance = 2
}

export enum CheckoutFinanceType {
    none = 0,
    santander = 1,
    ecster = 2
}

export interface FileResource {

    /**
     * File name.
     */
    name: string;

    /**
     * File url.
     */
    url: string;

    /**
     * File extension without the dot.
     */
    ext: string;

    /**
     * File size on disk.
     */
    size?: number;
}

/**
 * Order property type to sort by.
 */
export enum ObjectOrder {

    /**
     * Natural sort order.
     */
    default = 0,

    /**
     * Order by latest.
     */
    latest = 1,

    /**
     * Order by price.
     */
    price = 2,

    /**
     * Order by title.
     */
    title = 3,

    /**
     * Order by year.
     */
    year = 4,

    /**
     * Order by mileage.
     */
    mileage = 5,

    /**
     * Order by engine effect.
     */
    effect = 6,

    /**
     * Random sort order.
     */
    random = 99
}

export interface ObjectQueryParameters {

    /**
     * Free text search
     */
    searchText: string;

    /**
     * Current page number.
     */
    page?: number;

    /**
     * Max number of items. See also @see OnlineShowroom.Models.ObjectQueryParameters.Offset.
     */
    max?: number;

    /**
     * Offset to start from. See also @see OnlineShowroom.Models.ObjectQueryParameters.Max.
     */
    offset?: number;

    /**
     * Sort order in the format "@see OnlineShowroom.Models.ObjectOrder.@see OnlineShowroom.Models.OrderDirection".
     */
    sortOrder: string;
    priceFrom?: number;
    priceTo?: number;
    yearFrom?: number;
    yearTo?: number;
    brands: any;
    categories: any;
    weightMin?: number;
    weightMax?: number;

    /**
     * Select only certain items with given object unique ids.
     */
    selectedObjects: any;

    /**
     * Only show items with a sale price.
     */
    campaignOnly?: boolean;

    /**
     * Hide sold items. Default behavior is used if this is unset.
     */
    hideSold?: boolean;

    /**
     * Filials to use. A filial name can be company location.
     */
    filials: any;

    /**
     * Vehicle condition.
     */
    condition?: Condition;

    /**
     * Vehicle type.
     */
    type?: VehicleType;
    bedsMin?: number;
    bedsMax?: number;
    layouts: any;
    lengthMin?: number;
    lengthMax?: number;
    widthMin?: number;
    widthMax?: number;
    heightMin?: number;
    heightMax?: number;
    passengersMin?: number;
    passengersMax?: number;
    gearbox: any;
    fuel: any;
    milageMin?: number;
    milageMax?: number;
    effectFrom?: number;
    effectTo?: number;
    heating: any;
}

/**
 * Paginated object query result.
 */
export interface ObjectQueryResult {

    /**
     * Items for current result.
     */
    items: Vehicle[];

    /**
     * Count of @see OnlineShowroom.Models.ObjectQueryResult.Items.
     */
    count: number;

    /**
     * Total count of all items.
     */
    total: number;
}

export enum OrderDirection {

    /**
     * Descending order.
     */
    desc = 0,

    /**
     * Ascending order.
     */
    asc = 1
}

export interface BoatEngineFilterDataType extends FilterDataType {
    type: VehicleType;
    effectInterval: FilterIntervalOption;
}

export interface FilterIntervalOption {
    min?: number;
    max?: number;
    step?: number;
    unit: string;
    formatNumber: boolean;
}

export interface FilterDataType {
    type: VehicleType;
    name: string;
    layouts: string[];
    filials: string[];
    brands: string[];
    categories: FilterCategory[];
    modelYearInterval: FilterIntervalOption;
    priceInterval: FilterIntervalOption;
    weightInterval: FilterIntervalOption;
}

export interface FilterCategory {
    id: string;
    title: string;
    icon: string;
}

export interface BoatFilterDataType extends FilterDataType {
    type: VehicleType;
    effectInterval: FilterIntervalOption;
    lengthInterval: FilterIntervalOption;
    passengerInterval: FilterIntervalOption;
    bedsInterval: FilterIntervalOption;
}

export interface CaravanFilterDataType extends FilterDataType {
    type: VehicleType;
    bedsInterval: FilterIntervalOption;
    lengthInterval: FilterIntervalOption;
    widthInterval: FilterIntervalOption;
    heightInterval: FilterIntervalOption;
    heating: string[];
}

export interface CarFilterDataType extends FilterDataType {
    type: VehicleType;
    enginePowerInterval: FilterIntervalOption;
    milageInterval: FilterIntervalOption;
    effectInterval: FilterIntervalOption;
    gearbox: string[];
    fuel: string[];
}

export interface FilterData {
    types: FilterDataType[];
    language: string;
    baseUrl: string;
}

export interface FilterQueryParameters {

    /**
     * Gets or sets a list of vehicle types to match against. Keep `null` to match all types.
     */
    vehicleTypes: VehicleType[];
}

export interface McAtvFilterDataType extends FilterDataType {
    type: VehicleType;
    milageInterval: FilterIntervalOption;
    effectInterval: FilterIntervalOption;
}

export interface MobileHomeFilterDataType extends FilterDataType {
    type: VehicleType;
    bedsInterval: FilterIntervalOption;
    lengthInterval: FilterIntervalOption;
    passengerInterval: FilterIntervalOption;
    milageInterval: FilterIntervalOption;
    effectInterval: FilterIntervalOption;
    widthInterval: FilterIntervalOption;
    heightInterval: FilterIntervalOption;
    gearbox: string[];
    heating: string[];
}

export interface SeascooterFilterDataType extends FilterDataType {
    type: VehicleType;
    lengthInterval: FilterIntervalOption;
    effectInterval: FilterIntervalOption;
    passengerInterval: FilterIntervalOption;
}

export interface SnowmobileFilterDataType extends FilterDataType {
    type: VehicleType;
    milageInterval: FilterIntervalOption;
    effectInterval: FilterIntervalOption;
}

export interface TrailerFilterDataType extends FilterDataType {
    type: VehicleType;
    lengthInterval: FilterIntervalOption;
}

export interface TransportFilterDataType extends CarFilterDataType {
    type: VehicleType;
}

export interface UnknownFilterDataType extends FilterDataType {
    type: VehicleType;
}

