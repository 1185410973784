/**
 * Expose and combine given object to the global Empori module object.
 *
 * @param eventName Event name to trigger on document.
 * @param obj Object to combine and expose.
 */
export function emporiModule(eventName: string, obj: Object) {
  const win = window as any;
  const exposedObj = Object.assign(win.empori || {}, obj);

  win.empori = exposedObj;

  const event = document.createEvent('Event');

  event.initEvent(eventName, false, false);
  (event as any).empori = exposedObj;

  document.dispatchEvent(event);
}