export const en_gb = {
  'currency.global': 'GBP',
  'currency.local': '£',
  'general.vehicle': 'Vehicle',
  'general.object': 'Object',
  'filter.campaignOnly': 'Show vehicles with discount only',
  'filter.noResult': 'No vehicles found',
  'filter.cat.alcove': 'Overcab',
  'filter.cat.double axle': 'Double axle',
  'filter.cat.semiintegratedwithroofbed': 'Low profile with dropdown bed',
  'filter.cat.semiintegrated': 'Low profile',
  'filter.cat.kombi': 'Combi',
  'filter.cat.hatchback': 'Hatchback',
  'filter.cat.integrated': 'A class',
  'filter.cat.offroad': 'Terrain',
  'filter.cat.other': 'Other',
  'filter.cat.single axle': 'Single axle',
  'filter.cat.snowmobile': 'Snowmobile',
  'filter.cat.sportscoupe': 'Coupe',
  'filter.cat.van': 'Van conversion',
  'filter.cat.hardtop': 'Hard top',
  'filter.cat.walkaround': 'Walk around',
  'filter.cat.cabin': 'Cabin',
  'filter.cat.bowrider': 'Bow rider',
  'filter.cat.midcabin': 'Mid cabin',
  'filter.cat.steering': 'Steering console',
  'filter.cat.rowboat': 'Rowboat',
  'filter.cat.rib': 'RIB',
  'filter.cat.sailboat': 'Sailboat',
  'filter.cat.rubberboatdinghy': 'Rubber boat/dinghy',
  'filter.cat.pilothouse': 'Pilothouse',
  'filter.cat.openboat': 'Open boat',
  'filter.cat.speedboat': 'Speedboat',
  'filter.cat.doubleender': 'Double ender',
  'filter.cat.yacht': 'Yacht',
  'filter.cat.commercialvessel': 'Commercial vessel',
  'filter.cat.jetski': 'Jetski',
  'filter.cat.inboard': 'Inboard',
  'filter.cat.outboard': 'Outboard',
  'filter.cat.fourwheeler': 'ATV',
  'filter.cat.lowbuilttrailer': 'Low built trailer',
  'filter.cat.highbuilttrailer': 'High built trailer',
  'filter.cat.snowmobiletrailer': 'Snowmobile trailer',
  'filter.cat.boattrailer': 'Boat trailer',
  'filter.cat.watersports': 'Water sports',
  'filter.cat.coveredtrailer': 'Covered trailer',
  'filter.cat.cabinettrailer': 'Cabinet trailer',
  'filter.cat.tippertrailer': 'Tipper trailer',
  'filter.cat.machinetrailer': 'Machine trailer',
  'filter.cat.autotransporter': 'Car transporter',
  'filter.cat.mctransporter': 'Motorcycle transporter',
  'filter.cat.wiremesh': 'Wire mesh',
  'filter.cat.unbrakedtrailer': 'Unbraked trailer',
  'filter.cat.othertrailer': 'Other trailer',
  'filter.cat.american_rv': 'American RV',
  'filter.cat.campervan': 'Campervan',
  'filter.condition.all': 'All',
  'filter.condition.new': 'New',
  'filter.condition.used': 'Used',
  'filter.list.all': 'All',
  'filter.list.selection': '{0} items: {1}',
  'filter.list.over': 'Starting from {0} {1}',
  'filter.list.under': 'Up to {0} {1}',
  'filter.list.interval': '{0} — {1} {2}',
  'filter.list.over.price': 'Starting from {1}{0}',
  'filter.list.under.price': 'Up to {1}{0}',
  'filter.list.interval.price': '{2}{0} — {1}',
  'filter.list.intervalValue.price': '{1}{0}',
  'filter.extra.hide': 'Hide extra filter options',
  'filter.extra.show': 'Show extra filter options',
  'filter.input.amount.unit': ' ',
  'filter.input.beds': 'Berth',
  'filter.input.brand': 'Make',
  'filter.input.effect': 'Horsepower',
  'filter.input.effect.unit': 'hp',
  'filter.input.filial': 'Location',
  'filter.input.gearbox': 'Gearbox',
  'filter.input.gearbox.automat': 'Automatic',
  'filter.input.gearbox.manuell': 'Manual',
  'filter.input.fuel': 'Fuel',
  'filter.input.layouts': 'Floorplan',
  'filter.input.layouts.alcoveBed': 'Alcove bed',
  'filter.input.layouts.doubleBed': 'Transverse Bed',
  'filter.input.layouts.longBeds': 'Single Beds',
  'filter.input.layouts.unknown': 'Unknown',
  'filter.input.layouts.nursery': 'Bunk Beds',
  'filter.input.layouts.other': 'Other',
  'filter.input.layouts.queenBed': 'Island Bed',
  'filter.input.layouts.roofBed': 'Drop down bed',
  'filter.input.layouts.cornerBed': 'Corner bed',
  'filter.input.length': 'Length',
  'filter.input.width': 'Width',
  'filter.input.height': 'Height',
  'filter.input.milage': 'Mileage',
  'filter.input.milage.unit': 'miles',
  'filter.input.passengers': 'Passengers',
  'filter.input.price': 'Price',
  'filter.input.price.unit': '£',
  'filter.input.weight': 'Total weight',
  'filter.input.year': 'Year',
  'filter.input.heating': 'Heating system',
  'filter.reset': 'Reset filter',
  'filter.search': 'Search',
  'filter.search.result': '{0} matches your search',
  'filter.search.showMore': 'Show more vehicles',
  'filter.search.title': 'Search with given parameters',
  'filter.searchPlaceholder': 'What are you looking for?',
  'filter.sort.created': 'Most recent',
  'filter.sort.priceAsc': 'Lowest price',
  'filter.sort.priceDesc': 'Highest price',
  'filter.sort.titleAsc': 'A to Z',
  'filter.sort.yearDesc': 'Year',
  'filter.sort.mileageAsc': 'Mileage',
  'filter.sort.effectDesc': 'Effect',
  'filter.sortResult': 'Sort by',
  'filter.typeTitle': 'Choose vehicle type',
  'object.action.buy': 'Buy online',
  'object.action.call': 'Call us',
  'object.action.interestForm': 'Your enquiry',
  'object.action.pdf': 'Save PDF',
  'object.action.pdf.failed': 'Could not save PDF',
  'object.action.pdf.processing': 'Saving...',
  'object.action.pdf.succeeded': 'PDF is downloading!',
  'object.pdf.adLink': 'Link to advert',
  'object.action.youTube': 'Show video',
  'object.campaignPrice': 'Save £{0}',
  'object.campaignPriceTitle': 'Discount',
  'object.campaignTitle': 'Discount',
  'object.equipmentDesc': 'This vehicle is equiped with factory accessories for:',
  'object.equipmentTitle': 'Equipment',
  'object.filialDesc': 'This vehicle is located at our facility in',
  'object.descHeader': 'Description',
  'object.notFound': 'Object could not be found',
  'object.orgPrice': 'org. price',
  'object.price': '£{0}',
  'object.priceExVat': 'ex. VAT',
  'object.priceIncVat': 'incl. VAT',
  'object.priceDetails': 'incl. equipment',
  'object.pricePerMonth': 'Payment from £{0} / month',
  'object.similarTitle': 'These vehicles might also be of your interest',
  'object.showMoreEquipment': 'Show all equipment',
  'object.showLessEquipment': 'Show less equipment',
  'object.panorama.desc': 'Click and drag to see interior.',
  'object.specItem.fuel.gasoline': 'Petrol',
  'object.specItem.fuel.diesel': 'Diesel',
  'object.specItem.fuel.hybridGasoline': 'Hybrid electric/petrol',
  'object.specItem.fuel.hybridDiesel': 'Hybrid electric/diesel',
  'object.specItem.fuel.naturalGas': 'Natural gas',
  'object.specItem.fuel.electricity': 'Electric',
  'object.specItem.fuel.gasolineEthanol': 'Petrol/Ethanol',
  'object.specItem.fuel.gasolineGas': 'Petrol/Gas',
  'object.specItem.axleType': 'Axle type',
  'object.specItem.beds': 'Berth',
  'object.specItem.condition': 'Condition',
  'object.specItem.condition.new': 'New',
  'object.specItem.condition.used': 'Used',
  'object.specItem.conditionlabel.new': 'New',
  'object.specItem.conditionlabel.used': 'Used',
  'object.specItem.engineType': 'Engine type',
  'object.specItem.gear': 'Gearbox',
  'object.specItem.gear.automatic': 'Automatic',
  'object.specItem.gear.manual': 'Manual',
  'object.specItem.gear.sequential': 'Sequential',
  'object.specItem.gearAuto': 'Automatic',
  'object.specItem.gearManual': 'Manual',
  'object.specItem.height': 'Height',
  'object.specItem.layout': 'Layout',
  'object.specItem.length': 'Length',
  'object.specItem.mileage': 'Mileage',
  'object.specItem.milageUnit': 'miles',
  'object.specItem.hours': 'Hours',
  'object.specItem.passengers': 'Passengers',
  'object.specItem.altPassengers': 'Passengers with driver',
  'object.specItem.power': 'Horsepower',
  'object.specItem.reg': 'Registration',
  'object.specItem.regDate': 'Date in traffic',
  'object.specItem.articleNr': 'Article no.',
  'object.specItem.totalWeight': 'Total weight',
  'object.specItem.maxLoad': 'Max load',
  'object.specItem.vehicleType': 'Vehicle type',
  'object.specItem.width': 'Width',
  'object.specItem.weight': 'Weight',
  'object.specItem.workingWeight': 'Kerb weight',
  'object.specItem.altWorkingWeight': 'Dry weight',
  'object.specItem.year': 'Year',
  'object.specTitle.engineinfo': 'Engine details',
  'object.specTitle.engine': 'Engine',
  'object.specTitle.measure': 'Measurements',
  'object.specTitle.onlyWeight': 'Weight',
  'object.specTitle.onlyMeasure': 'Measurements',
  'object.specTitle.model': 'Model',
  'object.status.reserved': 'Reserved',
  'object.status.sold': 'Sold',
  'object.type': 'Type',
  'object.type.jet': 'Jet boat',
  'object.type.jetski': 'Jet ski',
  'object.type.boat': 'Boat',
  'object.type.car': 'Car',
  'object.type.caravan': 'Caravan',
  'object.type.mcAtv': 'MC/ATV',
  'object.type.snowmobile': 'Snowmobile',
  'object.type.rv': 'Motorhome',
  'object.type.mobileHome': 'Motorhome',
  'object.type.trailer': 'Trailer',
  'object.type.transport': 'Transportation',
  'object.type.unknown': 'Unknown',
  'object.type.boatengine': 'Boat engine',
  'object.type.other': 'Other',
  'object.goBack': 'Go back',
  'object.files.header': 'Files',
  'seller.desc': 'Contact me for a test drive or more information about the vehicle.',
  'seller.title': 'Seller',
  'payment.monthly': 'Part payment',
  'payment.monthlyCost': '£{0} / month',
  'payment.downPayment.title': 'Down payment',
  'payment.downPayment.value': '£{0}',
  'payment.loanTime.title': 'Loan time',
  'payment.loanTime.value': '{0} months',
  'payment.calcInfo.title': 'Calculation information',
  'payment.calcInfo.limit': 'Monthly cost cannot be less than £{0}',
  'payment.financing.suggestion': 'Financial suggestion',
  'payment.calcInfo.downPayment': 'Down payment (min {0}%):',
  'payment.calcInfo.loan': 'Loan:',
  'payment.calcInfo.interestCost': 'Total interest:',
  'payment.calcInfo.total': 'Total amount (excl. fees):',
  'payment.calcInfo.summary': 'With a loan amount of £{0} that should be paid over {1} months, the monthly repayment will be £{2} with an interest of {3}%. This an example - contact us for a personal financial solution.',
  'payment.calcSummary.summary': '{1} % egenkapital. {2} % nom. rente / {3} % eff. rente. <br/>Lånebeløp: {4} {0} i {5} mnd. <br/>Kredittkostnad: {6} {0}. Totalt: {7} {0}.',
  'payment.cash.title': 'Price with cash payment',
  'payment.cash.info': 'Fyll i uppgifterna i formuläret. När du har avslutat din beställning kontaktar vi dig.',
  'payment.default.currency': '£',
  'payment.financing.notAvaiable': 'For this vehicle, please contact us for financing proposal.',
  'payment.financing.notAvaiableShort': 'Please contact us for finance proposal',
  'santander.error.timeout': 'Tid för signering har gått ut, försök igen.',
  'santander.status.pending': 'Signering behandlas av Santander. Kontrollerar status...',
  'santander.status.signed': 'Signering är klar och godkänd!.',
  'santander.status.error': 'Signering kunde inte göras och/eller har blivit avbruten.',
  'santander.status.mailSent': 'Ett mail har skickats med en länk till signering. Kontrollerar status...',
  'santander.status.submitting': 'Väntar på BankID. Verifiera med din enhet.',
  'santander.info': 'Santander Consumer Bank har lång erfarenhet inom fordonsfinansiering och har nära samarbeten med bil- och fritidshandare över hela landet. Vi är en del av den globala bankkoncernen Banco Santander, en av världens största banker.',
  'finance.status.error': 'Kunde inte göra en förfrågan. Försök igen senare eller kontakta din handlare.',
  'finance.status.signError': 'Kunde inte göra en förfrågan. Försök igen senare eller kontakta din handlare.',
  'finance.status.reserving': 'Reserverar fordon...',
  'finance.status.reserved': 'Tack för din beställning. Vi kommer att kontakta dig inom kort.',
  'finance.status.fetchingUrl': 'Hämtar signering...',
  'finance.buyer': 'Buyer',
  'finance.seller': 'Seller',
  'finance.payment': 'Payment',
  'finance.termInMonths': 'Term in months',
  'finance.nominalInterest': 'Nominal interest',
  'finance.effectiveInterest': 'Effective interest',
  'finance.financedAmount': 'Financed amount',
  'finance.downPayment': 'Down payment',
  'finance.administrationFee': 'Administration fee',
  'finance.arrangementFee': 'Arrangement fee',
  'finance.unit.month': 'month',
  'finance.unit.currency': '£',
  'finance.sign': 'Sign',
  'finance.order': 'Order',
  'finance.status.fetchingSign': 'Signing...',
  'finance.status.signing': 'Signering pågår i ny flik. Kontrollerar status...',
  'finance.status.signingInfo1': 'Click here',
  'finance.status.signingInfo2': 'om en ny flik i webbläsaren inte kunde öppnas.',
  'finance.party.name': 'Name',
  'finance.party.pnr': 'Personnummer',
  'finance.party.email': 'Email address',
  'finance.party.phone': 'Phone',
  'finance.form.infoHtml': 'Välj om du vill dela upp betalningen eller betala fordonet kontant. Ange dina uppgifter och skicka din förfrågan. Köpet är inte bindande och du betalar inget förrän du har haft kontakt med handlaren. <span>Om du valt finans innebär det att du ansöker om lånelöfte med Mobilt Bank ID och får svar direkt på skärmen.</span>',
  'finance.form.pnr': 'Personnummer',
  'finance.form.pnr.placeholder': 'ÅÅÅÅMMDDXXXX',
  'finance.form.firstname': 'Forename',
  'finance.form.lastname': 'Surname',
  'finance.form.address': 'Address',
  'finance.form.message': 'Message',
  'finance.form.address.placeholder': 'Street name 1',
  'finance.form.city': 'City',
  'finance.form.zipcode': 'Postal code',
  'finance.form.zipcode.placeholder': '123 45',
  'finance.form.email': 'Email address',
  'finance.form.phone': 'Phone',
  'finance.form.deliveryDate': 'Desired delivery date',
  'finance.form.gdprLabel': 'Behandling av personuppgifter',
  'finance.form.gdprTerms': 'Jag har kontrollerat att uppgifterna ovan är korrekta och har tagit del av och godkänner behandlingen av personuppgifter. Läs mer om hur dina personuppgifter behandlas.',
  'finance.form.send': 'Verifiera med BankID',
  'finance.form.reserve': 'Reserve',
  'finance.verification.confirmed': 'Din låneansökan har blivit godkänd. Om du väljer att gå vidare med din beställning upprättas finansieringsavtal tillsammans med handlaren.',
  'checkout.title': 'Buy online',
  'checkout.bookingErrorHtml': '<span><strong><i class="fas fa-times"></i>Vi kunde inte ta emot din förfrågan.</strong> Vi beklagar detta. Kontrollera gärna att du har fyllt i dina uppgifter rätt och att du inte har missat något obligatoriskt fält. Du får gärna testa igen senare eller höra av dig direkt till oss.</span>',
  'checkout.bookingApprovedHtml': '<i class="fas fa-check"></i>Tack för din ansökan om finansiering. Vi kommer att kontakta dig så snart som möjligt.',
  'checkout.bookingTimeOutHtml': '<i class="fas fa-exclamation-circle"></i>Vi fick inte in något svar från BankID med det personnummer du angav. Vänligen försök igen eller kontakta oss.',
  'checkout.bookingComplete': 'Tack för din förfrågan! Du kommer inom kort bli kontaktad av oss för att slutföra köpprocessen.',
  'checkout.bookingFail': 'Vi kunde inte ta emot din förfrågan. Vi beklagar detta, men försök gärna igen eller kontakta oss direkt.',
  'checkout.closeWindow': 'Close window',
  'checkout.payment.finance': 'Finance',
  'checkout.payment.cash': 'Cash',
  'form.interest.title': 'Interest form',
  'form.interest.submitted': 'Thank you for your submission!',
  'form.interest.policy': 'I agree that {0} will handle my information in accordance with the privacy policy.',
  'form.interest.policyWarning': 'You have to agree that {0} will handle your information in order to do a submission.',
  'form.interest.policyWarningChoise': 'You have to choose at least one option for {0} to be able to get in contact with you!',
  'form.interest.infoHtml': 'Are you interested in this vehicle?<br/>Fill this form below and we will contact you!',
  'form.interest.contactEmail': 'Contact me via email',
  'form.interest.contactPhone': 'Contact me via phone',
  'form.interest.name': 'Name',
  'form.interest.similar': 'Similar vehicles',
  'form.interest.send': 'Send',
  'form.interest.error.mandatory': 'Mandantory',
  'form.interest.error.email': 'Invalid email address',
  'form.interest.captcha.label': 'Verify that you are not a robot',
  'form.interest.captcha.placeholder': 'Please enter the text shown in the image below',
  'form.interest.captcha.validationError': 'Could not post the form due to a validation error. Please fill out the field and try again.',
  'form.common.sending': 'Sending data...',
  'share.main': 'Share',
  'share.facebook': 'Share on Facebook',
  'share.messenger': 'Share on Messenger',
  'share.mail': 'Share via mail',
  'share.mail.subject': 'Have you seen this {0}?',
  'share.copy': 'Copy link',
  'share.copy.copied': 'Link copied to clipboard!',
  'object.specItem.wheeldrive': 'Drivetrain',
  'object.specItem.wheeldrive.unknown': 'Unknown',
  'object.specItem.wheeldrive.frontWheel': 'Front-wheel drive',
  'object.specItem.wheeldrive.rearWheel': 'Rear-wheel drive',
  'object.specItem.wheeldrive.fourWheel': 'Four-wheel drive',
  'object.specItem.mileage.operatingHours': 'Engine hours'
};
