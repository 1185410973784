import * as React from 'react';
import * as core from './model/core';
import * as api from './api/vehicleFilterApi';
import { FilterSectionSettings, ObjectActionCallback } from './view/filter/objectFilterView';
import { ObjectSectionSettings } from './view/object/objectView';
import { autobind } from './decorator';
import { ViewType, getViewHandler } from './view/handler';
import { DsoSuspense } from './skeleton';
import { setCss } from './view/css';

const ObjectFilterView = React.lazy(() =>
  import(/* webpackChunkName: "os-object-filter-view" */'./view/filter/objectFilterView'));
const ObjectView = React.lazy(() =>
  import(/* webpackChunkName: "os-object-view" */'./view/object/objectView'));

export interface ViewSectionSettings {
  filter?: FilterSectionSettings;
  object?: ObjectSectionSettings;
}

interface AppProps {
  sectionSettings?: ViewSectionSettings;
  pageReload?: boolean;
}

interface AppState {
  view: ViewType;
  selectedObject?: core.Vehicle | null;
  scrollY: number;
  config?: core.GeneralConfig;
}

export function handleObjectDirectLink(
  object: core.Vehicle | null,
  pageReload = false,
  click?: ObjectActionCallback,
  link?: ObjectActionCallback) {

  if (object) {
    const objectLink = click?.(object) ?? link?.(object);

    if (objectLink) {
      if ((objectLink.startsWith('?') || objectLink.startsWith('#')) && !pageReload) {
        return false;
      } else {
        window.location.href = objectLink;
      }

      return true;
    }
  }

  return false;
}

export class App extends React.Component<AppProps, AppState> {

  private externalObjectId?: string;

  constructor(props: AppProps) {
    super(props);

    const prevView = getViewHandler().getView();

    let view = prevView?.view ?? ViewType.ObjectFilter;
    this.externalObjectId = props.sectionSettings?.object?.id;

    if (this.externalObjectId) {
      view = ViewType.Object;
    }

    this.state = {
      view,
      selectedObject: null,
      scrollY: window.scrollY
    };
  }

  componentDidMount() {
    this.getConfig();

    getViewHandler().onViewChange(view => {
      this.setState({ view: view.view });
    });
  }

  @autobind
  handleSelectObject(object: core.Vehicle | null) {
    const filterSettings = this.props.sectionSettings?.filter;
    const objectClick = filterSettings?.objectClick;
    const objectLink = filterSettings?.objectLink;

    if (handleObjectDirectLink(object, this.props.pageReload, objectClick, objectLink)) {
      return;
    }

    this.setState({
      selectedObject: object,
      view: object ? ViewType.Object : ViewType.ObjectFilter,
      scrollY: window.scrollY
    });
  }

  async getConfig() {
    const result = await api.getGeneralConfig();

    if (result.ok) {
      const { value } = result;

      this.setState({ config: value });
      setCss(value);
    }
  };

  changeView(view: ViewType) {
    this.setState({ view });
  }

  getView() {
    const settings = this.props.sectionSettings;

    switch (this.state.view) {
      case ViewType.ObjectFilter:
        return (
          <DsoSuspense>
            <ObjectFilterView
              settings={settings ? settings?.filter ?? {} : {}}
              selectObject={this.handleSelectObject}
              scrollPos={this.state.scrollY}
              config={this.state.config!} />
          </DsoSuspense>);

      case ViewType.Object:
        return (
          <DsoSuspense>
            <ObjectView
              settings={settings ? settings?.object ?? {} : {}}
              objectId={this.externalObjectId}
              selectRelatedObject={this.handleSelectObject}
              relatedObjectLink={settings?.filter?.objectLink}
              object={this.state.selectedObject}
              config={this.state.config!} />
          </DsoSuspense>);

      default:
        return null;
    }
  }

  render() {
    return (
      <div className='dso-app-container'>
        {this.state.config && this.getView()}
      </div>
    );
  }
}
