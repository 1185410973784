export const nb_no = {
  'currency.global': 'NOK',
  'currency.local': 'kr',
  'general.vehicle': 'Kjøretøy',
  'general.object': 'Objekter',
  'filter.campaignOnly': 'Vis kun {0} med kampanjepris',
  'filter.noResult': 'Ingen {0} å vise',
  'filter.cat.alcove': 'Alkove',
  'filter.cat.double axle': 'Doble aksler',
  'filter.cat.semiintegratedwithroofbed': 'Halvintegrert med senkeseng',
  'filter.cat.semiintegrated': 'Halvintegrert',
  'filter.cat.hatchback': 'Halvkombi',
  'filter.cat.integrated': 'Integrert',
  'filter.cat.offroad': 'Terreng',
  'filter.cat.other': 'Annet',
  'filter.cat.single axle': 'Enkeltakslet',
  'filter.cat.snowmobile': 'Snøscooter',
  'filter.cat.sportscoupe': 'Sportscoupe',
  'filter.cat.van': 'Van',
  'filter.cat.hardtop': 'Hardtop',
  'filter.cat.daycruiser': 'Hardtop',
  'filter.cat.walkaround': 'Hardtop',
  'filter.cat.cabin': 'Cabin',
  'filter.cat.bowrider': 'Bowrider',
  'filter.cat.midcabin': 'Mittcabin',
  'filter.cat.steering': 'Konsoll',
  'filter.cat.rowboat': 'Robåt',
  'filter.cat.rib': 'RIB',
  'filter.cat.sailboat': 'Seilbåt',
  'filter.cat.rubberboatdinghy': 'Gummibåt/Jolly',
  'filter.cat.pilothouse': 'Pilothouse',
  'filter.cat.openboat': 'Skjærgårdsjeep/Landstedsbåt',
  'filter.cat.speedboat': 'Speedbåt',
  'filter.cat.doubleender': 'Trebåt/Snekke',
  'filter.cat.yacht': 'Yacht',
  'filter.cat.commercialvessel': 'Yrkesbåt/Sjark/Skøyte',
  'filter.cat.jetski': 'Vannskoter',
  'filter.cat.inboard': 'Innenbords',
  'filter.cat.outboard': 'Påhengsmotor',
  'filter.cat.fourwheeler': 'ATV',
  'filter.cat.lowbuilttrailer': 'Lav varehenger',
  'filter.cat.highbuilttrailer': 'Høy varehenger',
  'filter.cat.snowmobiletrailer': 'Snøscooterhenger',
  'filter.cat.boattrailer': 'Båthenger',
  'filter.cat.watersports': 'Vannsport',
  'filter.cat.coveredtrailer': 'Kapellhenger',
  'filter.cat.cabinettrailer': 'Skaphenger',
  'filter.cat.tippertrailer': 'Tipphenger',
  'filter.cat.machinetrailer': 'Maskinhenger',
  'filter.cat.autotransporter': 'Biltransporthenger',
  'filter.cat.mctransporter': 'MC-Henger',
  'filter.cat.wiremesh': 'Nettgrind',
  'filter.cat.unbrakedtrailer': 'Ubremset tilhenger',
  'filter.cat.othertrailer': 'Annen henger',
  'filter.condition.all': 'Alle',
  'filter.condition.new': 'Ny',
  'filter.condition.used': 'Brukt',
  'filter.list.all': 'Alle',
  'filter.list.over': 'Over {0} {1}',
  'filter.list.under': 'Under {0} {1}',
  'filter.extra.hide': 'Skjule flere filtre',
  'filter.extra.show': 'Vis flere filtre',
  'filter.input.amount.unit': 'st',
  'filter.input.beds': 'Soveplasser',
  'filter.input.brand': 'Merke',
  'filter.input.effect': 'Hestekrefter',
  'filter.input.filial': 'Avdeling',
  'filter.input.gearbox': 'Girkasse',
  'filter.input.gearbox.automat': 'Automat',
  'filter.input.gearbox.manuell': 'Manuell',
  'filter.input.layouts': 'Planløsning',
  'filter.input.layouts.alcoveBed': 'Alkove',
  'filter.input.layouts.doubleBed': 'Dobbeltseng',
  'filter.input.layouts.longBeds': 'Enkle senger',
  'filter.input.layouts.unknown': 'Ukjent',
  'filter.input.layouts.nursery': 'Barneløsning',
  'filter.input.layouts.other': 'Øvrige',
  'filter.input.layouts.queenBed': 'Queen bed',
  'filter.input.layouts.roofBed': 'Takseng',
  'filter.input.length': 'Lengde',
  'filter.input.width': 'Bredde',
  'filter.input.height': 'Høyde',
  'filter.input.milage': 'Km.Stand',
  'filter.input.milage.unit': 'km',
  'filter.input.passengers': 'Reg. sitteplasser',
  'filter.input.price': 'Pris',
  'filter.input.price.unit': 'kr',
  'filter.input.weight': 'Totalvekt',
  'filter.input.year': 'Modellår',
  'filter.input.heating': 'Varmesystem',
  'filter.reset': 'Nullstill filter',
  'filter.search': 'Søk',
  'filter.search.result': 'Viser {0} {1}',
  'filter.search.showMore': 'Vis flere {0}',
  'filter.search.title': 'Søk med valgte parametre',
  'filter.searchPlaceholder': 'Hva leter du etter?',
  'filter.sort.created': 'Siste',
  'filter.sort.priceAsc': 'Laveste pris',
  'filter.sort.priceDesc': 'Høyeste pris',
  'filter.sort.titleAsc': 'Alfabetisk',
  'filter.sortResult': 'Sorter resultat',
  'filter.typeTitle': 'Velg {0}type',
  'object.action.buy': 'Kjøp online',
  'object.action.call': 'Ring oss',
  'object.action.interestForm': 'Jeg er interessert',
  'object.action.pdf': 'Lagre PDF',
  'object.action.pdf.failed': 'Kunne ikke lagre PDF',
  'object.action.pdf.processing': 'Lagrer...',
  'object.action.pdf.succeeded': 'PDF nedlasting!',
  'object.pdf.adLink': 'Lenke til annonse',
  'object.action.youTube': 'Vis film',
  'object.campaignPrice': 'Spar {0} kr',
  'object.campaignPriceTitle': 'Kampanjepris',
  'object.campaignTitle': 'Kampanje',
  'object.equipmentDesc': 'Dette {0} har ekstrautstyr for:',
  'object.equipmentTitle': 'Utstyr',
  'object.filialDesc': '{0}et finnes på vår avdeling i',
  'object.descHeader': 'Beskrivelse',
  'object.notFound': '{0}et finnes ikke',
  'object.orgPrice': 'ord. pris',
  'object.price': '{0} kr',
  'object.priceDetails': 'inkl. utstyr',
  'object.pricePerMonth': 'Delbetaling {0} kr pr. mnd',
  'object.similarTitle': 'Disse tilsvarende {0}ene er funnet til deg ',
  'object.showMoreEquipment': 'Vise alt utstyr',
  'object.showLessEquipment': 'Vise mindre utstyr',
  'object.panorama.desc': 'Trykk og dra for å se interiøret i {0}et.',
  'object.specItem.fuel.gasoline': 'Bensin',
  'object.specItem.fuel.diesel': 'Diesel',
  'object.specItem.fuel.hybridGasoline': 'HybridBensin',
  'object.specItem.fuel.hybridDiesel': 'HybridDiesel',
  'object.specItem.fuel.naturalGas': 'Gass',
  'object.specItem.fuel.electricity': 'Elektrisitet',
  'object.specItem.fuel.gasolineEthanol': 'Etanol',
  'object.specItem.fuel.gasolineGas': 'Bensin/Gass',
  'object.specItem.axleType': 'Akseltype',
  'object.specItem.beds': 'Soveplasser',
  'object.specItem.condition': 'Tilstand',
  'object.specItem.condition.new': 'Ny',
  'object.specItem.condition.used': 'Brukt',
  'object.specItem.conditionlabel.new': 'Ny',
  'object.specItem.conditionlabel.used': 'Brukt',
  'object.specItem.engineType': 'Motortype',
  'object.specItem.gear': 'Girkasse',
  'object.specItem.gearAuto': 'Automat',
  'object.specItem.gearManual': 'Manuell',
  'object.specItem.height': 'Høyde',
  'object.specItem.layout': 'Planløsning',
  'object.specItem.length': 'Lengde',
  'object.specItem.mileage': 'Km.stand',
  'object.specItem.milageUnit': 'Km',
  'object.specItem.hours': 'Timer',
  'object.specItem.passengers': 'Reg. sitteplasser',
  'object.specItem.altPassengers': 'Personer',
  'object.specItem.power': 'Effekt',
  'object.specItem.reg': 'Reg.nr',
  'object.specItem.regDate': 'Tatt i bruk',
  'object.specItem.articleNr': 'Artikkel.nr',
  'object.specItem.totalWeight': 'Totalvekt',
  'object.specItem.vehicleType': 'Kjøretøytype',
  'object.specItem.width': 'Bredde',
  'object.specItem.weight': 'Vekt',
  'object.specItem.workingWeight': 'Egenvekt',
  'object.specItem.altWorkingWeight': 'Vekt ink. motor',
  'object.specItem.year': 'Årsmodell',
  'object.specTitle.engineinfo': 'Motor og drivlinje',
  'object.specTitle.engine': 'Motor',
  'object.specTitle.measure': 'Mål og vekt',
  'object.specTitle.onlyWeight': 'Vekt',
  'object.specTitle.onlyMeasure': 'Mål',
  'object.specTitle.model': 'Utforming',
  'object.status.forsale': 'For sale',
  'object.status.reserved': 'Reservert',
  'object.status.sold': 'Solgt',
  'object.type.jet': 'Vannskoter',
  'object.type.jetski': 'Vannskoter',
  'object.type.boat': 'Båt',
  'object.type.car': 'Bil',
  'object.type.caravan': 'Campingvogn',
  'object.type.mcAtv': 'MC/ATV',
  'object.type.snowmobile': 'Snøscooter',
  'object.type.rv': 'Bobil',
  'object.type.mobileHome': 'Bobil',
  'object.type.trailer': 'Tilhenger',
  'object.type.transport': 'Varebil',
  'object.type.other': 'Øvrige',
  'seller.desc': 'Kontakt meg for prøvekjøring eller mer informasjon om {0}et.',
  'seller.title': 'Selger',
  'payment.monthly': 'Del opp betalingen',
  'payment.monthlyCost': '{0} kr pr. mnd',
  'payment.downPayment.title': 'Egenkapital',
  'payment.downPayment.value': '{0} kr',
  'payment.loanTime.title': 'Lånetid',
  'payment.loanTime.value': '{0} mnd',
  'payment.calcInfo.title': 'Slik er utregningen',
  'payment.calcInfo.limit': 'Minste tillatte månedskostnad er {0} kr',
  'payment.calcInfo.downPayment': 'Egenkapital (minst {0}%):',
  'payment.calcInfo.loan': 'Lån:',
  'payment.calcInfo.interestCost': 'Total rentekostnad',
  'payment.calcInfo.total': 'Totalbeløp',
  'payment.calcInfo.summary': 'Med et lån på {0} kr som skal betales over {1} måneder blir månedskostnaden {2} med en rente på {3}%. Finansieringsforslaget er et regneeksempel. Ev.Innbytte regnes som kontantandel. Kontakt oss for å få en personlig tilpasset løsning.',
  'payment.calcSummary.summary': '{1} % egenkapital. {2} % nom. rente / {3} % eff. rente. <br/>Lånebeløp: {4} {0} i {5} mnd. <br/>Kredittkostnad: {6} {0}. Totalt: {7} {0}.',
  'payment.cash.title': 'Pris ved kontant betaling',
  'payment.cash.info': 'Fyll inn opplysningene i formularet. Når du har fullført din bestilling vil vi kontakte deg.',
  'payment.default.currency': 'kr',
  'payment.financing.notAvaiable': 'For dette kjøretøyet, vennligst kontakt oss for et finansieringsforslag.',
  'payment.financing.notAvaiableShort': 'Ta kontakt for finansieringsforslag',
  'santander.error.timeout': 'Tid for signering har gått ut, forsøk igjen.',
  'santander.status.pending': 'Signering behandlas av Santander. Kontrollerer status...',
  'santander.status.signed': 'Signering er klar og godkjent!',
  'santander.status.error': 'Signering kunne ikke gjøres og/eller har blitt avbrutt.',
  'santander.status.mailSent': 'En mail er sendt med link til signering. Kontrollerer status...',
  'santander.status.submitting': 'Venter på BankID. Verifiser med din enhet.',
  'santander.info': 'Santander Consumer Bank har lang erfaring innen kjøretøyfinansiering og  har nært samarbeid med bil & fritidsforhandlere over hele landet. Vi er en del av det globale bankkonsernet Banco Santander, en av verdens største banker.',
  'finance.status.error': 'Din søknad kunne ikke fullføres. Forsøk igjen senere eller kontakt din forhandler.',
  'finance.status.signError': 'Din søknad kunne ikke fullføres. Forsøk igjen senere eller kontakt din forhandler.',
  'finance.buyer': 'Kjøper',
  'finance.seller': 'Selger',
  'finance.payment': 'Betaling',
  'finance.termInMonths': 'Kredittid',
  'finance.nominalInterest': 'Rente',
  'finance.effectiveInterest': 'Effektiv rente',
  'finance.financedAmount': 'Avbetalingsbeløp',
  'finance.downPayment': 'Kontantinsats',
  'finance.administrationFee': 'Administrationsavgift',
  'finance.arrangementFee': 'Oppstartsgebyr',
  'finance.unit.month': 'mnd',
  'finance.unit.currency': 'kr',
  'finance.sign': 'Signer',
  'finance.order': 'Bestill',
  'finance.status.fetchingSign': 'Henter signering....',
  'finance.status.signing': 'Signering pågår i ny fane. Kontrollerer status.....',
  'finance.status.signingInfo1': 'Klikk her',
  'finance.status.signingInfo2': 'om en ny fane i webleseren ikke kunne åpnes.',
  'finance.verification.confirmed': 'Din lånesøknad har blitt godkjent. Om du velger å gå videre med din bestilling opprettes finansieringsavtalen sammen med din forhandler. ',
  'finance.status.reserving': 'Reserver kjøretøy....',
  'finance.status.reserved': 'Takk for din bestilling. Vi kommer til å kontakte deg innen kort tid.',
  'finance.status.fetchingUrl': 'Henter signering...',
  'finance.party.name': 'Navn',
  'finance.party.pnr': 'Personnummer',
  'finance.party.email': 'E-postadresse',
  'finance.party.phone': 'Telefon',
  'finance.form.infoHtml': 'Velg om du vil dele opp betalingen eller betale kjøretøyet kontant. Angi dine opplysninger og send inn din forespørsel. Kjøpet er ikke bindende og du betaler ingenting før du har hatt dialog med forhandler.<span>Om du har valgt finansiering, innebærer det at søker om et finansieringsbevis med Mobil Bank ID og får svar direkte på skjermen.</span>',
  'finance.form.pnr': 'Personnummer',
  'finance.form.pnr.placeholder': 'DDMMÅÅÅÅXXXX',
  'finance.form.firstname': 'Fornavn',
  'finance.form.lastname': 'Etternavn',
  'finance.form.address': 'Adresse',
  'finance.form.message': 'Melding',
  'finance.form.address.placeholder': 'Gateadresse ',
  'finance.form.city': 'Sted',
  'finance.form.zipcode': 'Postnummer',
  'finance.form.zipcode.placeholder': '123 45',
  'finance.form.email': 'E-postadresse',
  'finance.form.phone': 'Telefon',
  'finance.form.deliveryDate': 'Ønsket leveringsdato',
  'finance.form.gdprLabel': 'Behandling av personopplysninger',
  'finance.form.gdprTerms': 'Jeg har kontrollert at opplysningene er korrekte og har kontrollert og godkjenner behandlingen av personopplysninger. Les mer her om hvordan dine personopplysninger behandles. ',
  'finance.form.send': 'Verifiser med BankID',
  'finance.form.reserve': 'Bestill',
  'checkout.title': 'Kjøp online',
  'checkout.bookingErrorHtml': '<span><strong><i class="fas fa-times"></i> Vi kunne ikke behandle din forespørsel.</strong> Vi beklager dette. Kontroller gjerne at du har fylt inn dine opplysninger korrekt eller har glemt å fylle inn et obligatorisk felt. Du må gjerne prøve igjen eller ta kontakt med oss direkte.</span>',
  'checkout.bookingApprovedHtml': '<i class="fas fa-check"></i>Takk for din søknad om finansiering. Vi vil kontakte deg så snart som mulig. ',
  'checkout.bookingTimeOutHtml': '<i class="fas fa-exclamation-circle"></i>Vi fikk ikke respons fra Bank ID med personnummeret du oppga. Vennligst forsøk igjen eller kontakt oss.',
  'checkout.bookingComplete': 'Takk for din forespørsel! Du kommer innen kort tid til å bli kontaktet av oss for å sluttføre kjøpsprosessen.',
  'checkout.bookingFail': 'Vi kunne ikke ta imot din forespørsel. Vi beklager dette, men forsøk igjen eller kontakt oss direkte.',
  'checkout.closeWindow': 'Steng vinduet',
  'checkout.payment.finance': 'Finansiering',
  'checkout.payment.cash': 'Kontant',
  'form.interest.title': 'Meld interesse',
  'form.interest.submitted': 'Takk for din interesse!',
  'form.interest.policy': 'Jeg godkjenner at {0} behandler mine opplysninger i henhold til personvernerklæringen.',
  'form.interest.policyWarning': 'Du må godkjenne at {0} behandler dine opplysninger for å kunne melde interesse.',
  'form.interest.policyWarningChoise': 'Du må velge minst et alternativ på hvordan {0} skal nå deg!',
  'form.interest.infoHtml': 'Er du interessert i å få vite mer om dette kjøretøyet? <br/>Fyll inn dine opplysninger nedenfor så kontakter vi deg!',
  'form.interest.contactEmail': 'Kontakt meg via e-post',
  'form.interest.contactPhone': 'Kontakt meg via telefon',
  'form.interest.name': 'Navn',
  'form.interest.similar': 'Av lignende kjøretøy',
  'form.interest.send': 'Send',
  'form.interest.captcha.label': 'Bekreft at du ikke er en robot',
  'form.interest.captcha.placeholder': 'Skriv texten som visas i bilden',
  'form.interest.captcha.validationError': 'Formuläret kunde inte skickas på grund av ett valideringsfel. Fyll i fältet på nytt och försök igen',
  'form.common.sending': 'Sender data...',
  'share.main': 'Del',
  'share.facebook': 'Del på Facebook',
  'share.messenger': 'Del på Messenger',
  'share.mail': 'Del via mail',
  'share.mail.subject': 'Har du sett dette {0}et?',
  'share.copy': 'Kopier lenke',
  'share.copy.copied': 'Link kopiert til utklippstavlen!',
  'object.specItem.wheeldrive': 'Drivhjul',
  'object.specItem.wheeldrive.unknown': 'Ukjent',
  'object.specItem.wheeldrive.frontWheel': 'Forhjulstrekk',
  'object.specItem.wheeldrive.rearWheel': 'Bakhjuldrift',
  'object.specItem.wheeldrive.fourWheel': 'Firehjulstrekk',
  'object.specItem.mileage.operatingHours': 'Motortimer'
};
