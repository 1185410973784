import { defaultLang, InterfaceLanguageCultures, Langs } from './lang';
import { GeneralConfig, Vehicle, VehicleExtended, VehicleType } from './model/core';

export const isDev = process.env.NODE_ENV !== 'production';
export const stringListSeparator = ';';

export let baseUrl = '';
let lang = defaultLang;

export function setBaseUrl(url: string) {
  baseUrl = url;
}

/**
 * @param num Number to format
 * @param precision Decimals to use (if present)
 * @param separator Format separator
 */
function formatNumberWith(num: number, precision = 0, separator = ' ') {
  if (!isFinite(num)) {
    return num.toString();
  }

  let n = num.toFixed(precision || 0).split('.');
  n[0] = n[0].replace(/\d(?=(\d{3})+$)/g, '$&' + (separator || ','));
  return n.join('.');
}

type FormatNumberFunc = (num: number, precision?: number) => string;

export let formatNumber: FormatNumberFunc = (num: number, precision = 0) => formatNumberWith(num, precision, ' ');

export function setLanguage(newLang: Langs) {
  lang = newLang;

  if (lang && lang === InterfaceLanguageCultures.en) {
    formatNumber = (num: number, precision = 0) => formatNumberWith(num, precision, ',');
  }
}

export function getUniqueKey() {
  return Math.random().toString(16).substring(2);
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelCase(str: string): string {
  if (!str) {
    return '';
  }

  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function convertToCSSClassname(str: string) {
  return str.replace(' ', '-').toLowerCase();
}

export function getEnumStringValue<T>(enumObj: T, enumVal: number): keyof T | undefined {
  return (enumObj as any)[enumVal];
}

export function getEnumValue<T>(enumObj: T, enumString: string): T | undefined {
  return (enumObj as any)[enumString];
}

export function getEnumValues(enumObj: any) {
  return Object.keys(enumObj)
    .filter(key => !isNaN(+key))
    .map(key => ({
      key: +key,
      value: enumObj[key] + ''
    }));
}

export function getEnumValueCamel<T extends number>(enumObj: any, value: string | number): T {
  if (typeof value === 'number') {
    return value as any;
  }

  return enumObj[camelCase(value)] as any;
}

export function getEnumTypes(enumObj: any, types: (string | number)[]): number[] {
  const keys = Object.keys(enumObj).filter(key => isNaN(+key));
  const res: number[] = [];

  for (let type of types) {
    if (!type) {
      continue;
    }

    if (typeof type === 'number' && type in enumObj) {
      res.push(type);
    } else {
      let strType = type as string;
      let enumKey = keys.find(key => key.toLowerCase() === strType.toLowerCase());

      if (enumKey) {
        res.push(enumObj[enumKey]);
      }
    }
  }

  return res;
}

export function debounce<T extends Function>(func: T, wait: number, immediate = false): T {
  let timeout: number;

  const res = function (this: any) {
    let that = this;
    let args: any[] = [];

    for (let i = 0; i < arguments.length; i++) {
      args.push(arguments[i]);
    }

    const later = () => {
      timeout = 0;
      if (!immediate) {
        func.apply(that, args);
      }
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait) as any;

    if (callNow) {
      func.apply(that, args);
    }
  };

  return res as any;
}

const formatRegex = new RegExp('{-?[0-9]+}', 'g');

export function formatString(str: string, ...args: any[]) {
  return str.replace(formatRegex, item => {
    const intVal = parseInt(item.substring(1, item.length - 1), 10);

    if (intVal >= 0) {
      return args[intVal];
    } else if (intVal === -1) {
      return '{';
    } else if (intVal === -2) {
      return '}';
    }

    return '';
  });
}

/**
 * Validate a swedish birth or organisation id.
 *
 * @param orgNr Birth id and/or org nr to validate
 */
export function validateOrgNum(orgNr: string) {
  let arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

  if (!orgNr) {
    return false;
  }

  let separator = /[-\+]/;

  if (!separator.test(orgNr) && orgNr.length > 4) {
    // Assume and insert a "-"" separator.
    let index = orgNr.length - 4;
    orgNr = orgNr.slice(0, index) + '-' + orgNr.slice(index);
  }

  let bid = orgNr.split(separator);
  let date = bid[0];
  let check = bid[1];

  if (date.length === 8) {
    date = date.substring(2);
  }

  if (date.length !== 6 || (check && check.length !== 4)) {
    return false;
  }

  let numbers = (date + check).split('');
  let len = numbers.length;
  let bit = 1;
  let sum = 0;

  while (len--) {
    let val = +numbers[len] | 0;
    sum += (bit ^= 1) ? arr[val] : val;
  }

  return !!sum && sum % 10 === 0;
}

const regNrRegex = /[A-Z]{3}[0-9]{2}[0-9A-Z]/ig;

export function isValidRegNr(val: string) {
  return regNrRegex.test(val);
}

export const seperateThousandsWithinString = (value: string) => {
  const numbers = value.replace(/\D/g, '');
  const nonNumeric = value.replace(/[0-9]/g, '');

  const newValue = formatNumber(parseInt(numbers, 10));
  return `${newValue}${nonNumeric}`;
};

export const seperateThousands = (value: number) => formatNumber(value);

export function getObjectYearString(object: Vehicle) {
  return object.regMark ? `${object.year} (${object.regMark})` : object.year + '';
}

export function getCampaignBox(object: Vehicle, config: GeneralConfig) {
  return config.campaignBoxes
    ?.find(cb => (cb.vehicleType === VehicleType.anything || cb.vehicleType === object.type)
    && (cb.yearModel <= 0 || cb.yearModel === object.year));
}

