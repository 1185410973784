import { sv_se } from './sv_se';
import { en_gb } from './en_gb';
import { nb_no } from './nb_no';
import { de_de } from './de_de';
import { formatString, isDev, setLanguage as setUtilLanguage } from '../util';
import { VehicleType } from '../model/core';

export const InterfaceLanguageCultures = {
  sv: 'sv-SE',
  en: 'en-GB',
  nb: 'nb-NO',
  de: 'de-DE'
};

const languages = {
  sv_se,
  en_gb,
  nb_no,
  de_de,

  // Fallback for backwards compatibility. Don't use for anything new.
  sv: sv_se,
  en: en_gb,
  nb: nb_no,
  de: de_de
};

export type Langs = keyof typeof languages;
export const defaultLang: Langs = 'sv_se';

let currLang: Langs = 'sv_se';

export type LangKey = keyof typeof sv_se;

export function setLanguage(lang: string) {
  if (lang) {
    // Convert for example sv-SE to sv_se.
    lang = lang.toLowerCase().replace('-', '_');
  }

  currLang = lang as Langs;

  if (!languages[currLang]) {
    console.warn(`Unknown language "${currLang}"; using default language "${defaultLang}" instead.`);
    currLang = defaultLang;
  }

  setUtilLanguage(currLang);
}

export function translate(key: LangKey, defaultValue?: any): string {
  const lang = (languages as any)[currLang];
  let val = lang[key];

  if (val) {
    return val;
  }

  if (isDev) {
    console.groupCollapsed(`[${currLang}] Missing translation for key "${key}"`);
    console.trace();
    console.groupEnd();
  }

  val = defaultValue ?? (languages as any)[defaultLang][key];
  lang[key] = val;

  return val || key;
}

export function translateAny(key: string, defaultValue?: any) {
  return translate(key as any, defaultValue);
}

export function translateFormat(key: LangKey, ...args: any[]) {
  return formatString(translate(key), ...args);
}

export function generalLowercaseTrans(type: VehicleType, lang: string) {
  const translated = translate(
    type === VehicleType.boat ||
    type === VehicleType.jetski ||
    type === VehicleType.boatEngine
      ? 'general.object'
      : 'general.vehicle'
  );

  if (lang.startsWith('de')) {
    return translated;
  }

  return translated.toLocaleLowerCase();
}