import { ViewHandler } from './viewHandler';
import { SearchViewHandler } from './search';
import { HashViewHandler } from './hash';

export enum ViewType {
  ObjectFilter,
  Object
}

export interface View {
  key: string;
  view: ViewType;
  query?: string | null;
}

export const views: Record<ViewType, View> = {
  [ViewType.ObjectFilter]: {
    key: 'objects',
    view: ViewType.ObjectFilter
  },

  [ViewType.Object]: {
    key: 'object',
    view: ViewType.Object
  }
};

export enum ViewHandlerType {
  Hash,
  Search
}

let handler: ViewHandler = new HashViewHandler();

export function getViewHandler(): ViewHandler {
  return handler;
}

function createViewHandler(type: ViewHandlerType): ViewHandler {
  switch (type) {
    case ViewHandlerType.Hash:
      return new HashViewHandler();
    case ViewHandlerType.Search:
      return new SearchViewHandler();
    default:
      throw 'Unhandled search type ' + type;
  }
}

export function setViewHandlerType(type: ViewHandlerType) {
  handler = createViewHandler(type);
}
